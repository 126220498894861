.tagListForm > div {
	margin-right: 3%;
	margin-top: 3%;
}
.tagListScroll > div {
	margin-right: 1%;
}
.tagListScroll::-webkit-scrollbar {
	width: 0; /* width of the entire scrollbar */
}

.tagListScroll::-webkit-scrollbar-track {
	background: none; /* color of the tracking area */
}

.tagListScroll::-webkit-scrollbar-thumb {
	background-color: none; /* color of the scroll thumb */
}

.tagListForm .MuiAutocomplete-inputRoot {
	padding: 3px;
}
