.cat-shape {
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  width: auto;
  /* margin: 4px; */
  position: relative;
  font-weight: normal;
  color: #00000086;
  box-shadow: 0 3px 6px -3px rgb(130, 130, 130);
  margin-right: 4px;
  margin-bottom: 4px;
}

.cat-intent-positive {
    color: rgb(149, 215, 135);
    border: 1px solid rgb(149, 215, 135);
}

.cat-intent-neutral {
    color: rgb(174, 165, 141);
    border: 1px solid rgb(174, 165, 141);
}

.cat-intent-negative {
    color: rgb(251, 148, 148);
    border: 1px solid rgb(251, 148, 148);
}

.categories-display {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* margin: 5px; */
}