.custom-handle {
	position: absolute;
	width: 8px;
	height: 8px;
	background-color: #1153aa;
	opacity: 0.75;
	border-radius: 4px;
	z-index: 4000;
	bottom: -4px;
	right: -4px;
	cursor: se-resize;
}

.pointerEventsAll {
	pointer-events: all;
}

.attachementContainer {
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.attachementContainerImage {
	height: 30vh;
}

.attachementContainerCheckbox {
	position: relative;
	width: 100%;
	text-align: center;
}

.attachementContainerView {
	position: absolute;
	right: 0;
	top: 0;
}

.fileIconContainer {
	height: 30vh;
	width: 12vw;
	display: flex;
	align-items: center;
}
