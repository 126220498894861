.logoContainer .logoActions {
	width: 40px;
	height: 40px;
	display: flex;
	overflow: hidden;
	position: relative;
	font-size: 1.25rem;
	align-items: center;
	flex-shrink: 0;
	line-height: 1;
	justify-content: center;
}

.cardActions {
	cursor: pointer;
}

.linkHover {
	margin: 0px;
	/*  marginBottom: "5px", */
	font-style: italic;
	color: #9c27b0;
	cursor: pointer;
}

.linkHover:hover {
	color: #791e89;
}
