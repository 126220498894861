
.loading {
  background: rgb(255, 255, 255) url('https://thumbs.gfycat.com/DirectPartialBumblebee-size_restricted.gif') no-repeat 50% 50%;
  width: 50%;
  -webkit-transition: background-color 0;
  background-size: 200px;
  transition: background-color 0;
  opacity: 0;
  -webkit-transition: opacity 0;
  transition: opacity 0;
}



    .display-img-html img {
      max-width: 80%;
    }
    .display-img-html p{
      text-align: left;
    }

    .ql-snow .ql-editor img {
      max-width: 500px !important;
    }
