.trustpilotReviewType {
	background-color: #00b67a;
	padding: 1% 2%;
	margin-left: 1vw;
	border-radius: 100px;
	font-weight: 400;
	color: #191919;
}
.trustpilotReviewTypeReported {
	background-color: #e45f4f;
	padding: 1% 2%;
	margin-left: 1vw;
	border-radius: 100px;
	font-weight: 400;
	color: #ffffff;
}
.showDicoType {
	background-color: #5aa7ff;
	padding: 1% 2%;
	margin-left: 1vw;
	border-radius: 100px;
	font-weight: 400;
	color: #191919;
}
