.toggle-container {
  display: flex;
  align-items: center;
  font-family: "Roboto";
}

.toggle-container p {
  font-weight: 500;
  margin: 0;
}

.toggle {
    margin: 8px;
    isolation: isolate;
    position: relative;
    height: 18px;
    width: 40px;
    border-radius: 50px;
    overflow: hidden;
    background-color: #ffffff;
    cursor: pointer;
    border: 0.5px solid gray;
}

.toggle-state {
  display: none;
}

.indicator {
  height: 100%;
  width: 200%;
  background:  #999999;;
  border-radius: 15px;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
}
.lng-label{
      font-size: 14px;
    line-height: 1.42857;
    color: #171717;
    font-weight: 600;
}
.toggle-state:checked ~ .indicator {
  transform: translate3d(25%, 0, 0);
}

@media screen and (min-width: 850px) {
  .toggle-container { 
    width: 110px;
    padding: 5px;
    border-radius: 10px;
  }
}